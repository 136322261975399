import ReactGA from 'react-ga4';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import reportWebVitals from './reportWebVitals';

import './styles/globals.css';
import { lazy } from 'react';

const NotFoundPage = lazy(() =>
  import('./pages/NotFoundPage')
    .then(({ NotFoundPage }) => ({ default: NotFoundPage })),
);

const Home = lazy(() =>
  import('./pages/Home')
    .then(({ Home }) => ({ default: Home })),
);

const TermsAndConditions = lazy(() =>
  import('./pages/TermsAndConditions')
    .then(({ TermsAndConditions }) => ({ default: TermsAndConditions })),
);

const DefaultService = lazy(() =>
  import('./pages/default-service')
    .then(({ DefaultService }) => ({ default: DefaultService })),
);

const WeddingHub = lazy(() =>
  import('./pages/wedding-hub')
    .then(({ WeddingHub }) => ({ default: WeddingHub })),
);

const SeatingPlanner = lazy(() =>
  import('./pages/seating-planner')
    .then(({ SeatingPlanner }) => ({ default: SeatingPlanner })),
);

const Baptism = lazy(() =>
  import('./pages/baptism')
    .then(({ Baptism }) => ({ default: Baptism })),
);

const PresentationSaveTheDate = lazy(() =>
  import('./pages/presentation/save-the-date')
    .then(({ PresentationSaveTheDate }) => ({ default: PresentationSaveTheDate })),
);

const PresentationWeddingHub = lazy(() =>
  import('./pages/presentation/wedding-hub')
    .then(({ PresentationWeddingHub }) => ({ default: PresentationWeddingHub })),
);

const PresentationSeatingPlanner = lazy(() =>
  import('./pages/presentation/seating-planner')
    .then(({ PresentationSeatingPlanner }) => ({ default: PresentationSeatingPlanner })),
);

// Initialize React Ga with your tracking ID
ReactGA.initialize('G-LC7KKF93J8');

const rootw = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

rootw.render(
  <Router>
    <Routes>
      <Route path="/" Component={Home} />
      <Route path="/terms-and-conditions" Component={TermsAndConditions} />

      {/* Route for Wedding Detail page */}
      <Route path="/:event_id" Component={DefaultService} />
      <Route path="/:event_id/wedding-hub/" Component={WeddingHub} />
      <Route path="/:event_id/seating-planner/" Component={SeatingPlanner} />
      <Route path="/:event_id/baptism/" Component={Baptism} />

      {/* Route for Presentation pages */}
      <Route path="/presentation/wedding-hub" Component={PresentationWeddingHub} />
      <Route path="/presentation/save-the-date" Component={PresentationSaveTheDate} />
      <Route path="/presentation/seating-planner/:event_id" Component={PresentationSeatingPlanner} />

      {/* Route for 404 Not Found page */}
      <Route path='*' Component={NotFoundPage} />
    </Routes>
  </Router>
);

reportWebVitals();
